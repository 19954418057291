<template>
  <div class="etc increment-wrap">
    <div class="line" />
    <h2>ETC服务</h2>
    <div class="img">
      <!-- <div class="title">ETC服务</div> -->
      <div style="padding-top:70px; text-align:center;"><img src="./../../assets/img/10.jpg" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
</style>
